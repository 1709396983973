<template>
	<div class="my-account">
		<div class="header-menu va-row">
			<div class="va-row">
				<div class="flex container-ddh-menu xs12 with-bottom va-row">
					<div class="flex xs6 no-padding">
						<h1>Escolas</h1>
					</div>
					<div class="flex xs6 no-padding text-right">
						<br /><br />
						<!-- {{emptySchool}} -->

						<button
							v-on:click="showSchool(emptySchool, 'add')"
							class="btn btn-change"
						>
							ADICIONAR ESCOLA
						</button>
						<!-- &nbsp;
               <button v-on:click="clickOnFile()"
                  class="btn btn-change">
                  UPLOAD PLANILHA
               </button>
               <input type="file" id="files" class="hide" ref="files" v-on:change="handleFileUpload()"/> -->
					</div>
				</div>
			</div>
			<div class="clearfix"></div>
		</div>

		<div class="container-dhh va-row">
			<div class="flex md12 xs12 no-padding">
				<h4>Escolas Cadastradas</h4>
			</div>
			<div class="flex md12 xs12 no-padding">
				<div class="ui-grid">
					<div class="my-data-table va-row" id="cards">
						<div class="flex xs12 no-padding fiscal-notes">
							<div class="body-spacing">
								<div class="va-row">
									<div class="sets-list va-row">
										<vuestic-tree-root ref="treeView">
											<vuestic-tree-category
												v-for="(item, id) in list"
												:key="id"
											>
												<div class="va-row" slot="content-line">
													<div class="flex xs3 no-padding">
														{{ item.name }}
													</div>
													<div class="flex xs2 no-padding text-left">
														{{ item.contact_name }}
													</div>
													<div class="flex xs3 no-padding text-left">
														{{ item.email }}
													</div>
													<div class="flex xs2 no-padding text-right">
														{{ item.contact_phone }}
													</div>
													<div class="flex xs2 no-padding text-right">
														<button
															v-on:click.stop.prevent="showSchool(item, 'view')"
															class="btn-change-edit"
														>
															<span class="fa fa-eye"></span>
														</button>
														&nbsp;
														<button
															v-on:click.stop.prevent="showSchool(item, 'edit')"
															class="btn-change-edit"
														>
															<span class="fa fa-edit"></span>
														</button>
														&nbsp;
														<button
															v-on:click.stop.prevent="
																showSchool(item, 'delete')
															"
															class="btn-change-edit"
														>
															<span class="fa fa-trash"></span>
														</button>
													</div>
												</div>

												<vuestic-tree-node
													v-for="(grade, idGrade) in item.grades"
													:key="idGrade"
												>
													<div class="va-row">
														<div class="flex xs3 no-padding">
															Turma: {{ grade.name }}
														</div>
														<div class="flex xs2 no-padding text-left">
															<span v-if="grade.teacher">{{
																grade.teacher.name
															}}</span>
														</div>
														<div class="flex xs3 no-padding text-left">
															<span v-if="grade.teacher">{{
																grade.teacher.email
															}}</span>
														</div>
														<div class="flex xs2 no-padding text-right">
															Quantidade de alunos: <b>{{ grade.size }}</b>
														</div>
														<div class="flex xs2 no-padding text-right">
															<button
																v-on:click.stop.prevent="
																	showGrade(grade, item, 'view')
																"
																class="btn-change-edit"
															>
																<span class="fa fa-eye"></span>
															</button>
															&nbsp;
															<button
																v-on:click.stop.prevent="
																	showGrade(grade, item, 'edit')
																"
																class="btn-change-edit"
															>
																<span class="fa fa-edit"></span>
															</button>
															&nbsp;
															<button
																v-on:click.stop.prevent="
																	showGrade(grade, item, 'delete')
																"
																class="btn-change-edit"
															>
																<span class="fa fa-trash"></span>
															</button>
														</div>
													</div>
												</vuestic-tree-node>

												<div class="flex xs12 no-padding-left">
													<button
														v-on:click="showGrade(emptyGrade, item, 'add')"
														class="btn btn-change"
													>
														ADICIONAR TURMA
													</button>
													<!-- &nbsp;
                                      <button v-on:click="clickOnFile()"
                                          class="btn btn-change">
                                          UPLOAD PLANILHA TURMAS
                                      </button> -->
												</div>
											</vuestic-tree-category>
										</vuestic-tree-root>
									</div>
								</div>

								<vuestic-modal
									:show.sync="show"
									v-bind:large="true"
									ref="largeModal"
									:okText="'modal.confirm' | translate"
									:cancelText="'modal.cancel' | translate"
									:noButtons="true"
								>
									<div slot="title" v-if="schoolModalType === 'view'">
										Visualizar escola
									</div>
									<div slot="title" v-if="schoolModalType === 'edit'">
										Editar escola
									</div>
									<div slot="title" v-if="schoolModalType === 'delete'">
										Excluir escola
									</div>
									<div slot="title" v-if="schoolModalType === 'add'">
										Adicionar escola
									</div>

									<div v-if="schoolSelected !== null">
										<school-edit
											:school="schoolSelected"
											:typeSend="'post'"
											v-if="schoolModalType === 'add'"
										></school-edit>
										<school-edit
											:school="schoolSelected"
											:typeSend="'put'"
											v-if="schoolModalType === 'edit'"
										></school-edit>

										<school-view
											:school="schoolSelected"
											v-if="schoolModalType === 'view'"
										></school-view>
										<school-delete
											:school="schoolSelected"
											v-if="schoolModalType === 'delete'"
										></school-delete>
									</div>
								</vuestic-modal>

								<vuestic-modal
									:show.sync="show"
									v-bind:large="true"
									ref="gradeModal"
									:okText="'modal.confirm' | translate"
									:cancelText="'modal.cancel' | translate"
									:noButtons="true"
								>
									<div slot="title" v-if="gradeModalType === 'view'">
										Visualizar turma
									</div>
									<div slot="title" v-if="gradeModalType === 'edit'">
										Editar turma
									</div>
									<div slot="title" v-if="gradeModalType === 'delete'">
										Excluir turma
									</div>
									<div slot="title" v-if="gradeModalType === 'add'">
										Adicionar turma
									</div>

									<div v-if="gradeSelected !== null">
										<grade-edit
											:grade="gradeSelected"
											:school="schoolSelected"
											:typeSend="'post'"
											v-if="gradeModalType === 'add'"
										></grade-edit>
										<grade-edit
											:grade="gradeSelected"
											:school="schoolSelected"
											:typeSend="'put'"
											v-if="gradeModalType === 'edit'"
										></grade-edit>

										<grade-view
											:grade="gradeSelected"
											v-if="gradeModalType === 'view'"
										></grade-view>
										<grade-delete
											:grade="gradeSelected"
											v-if="gradeModalType === 'delete'"
										></grade-delete>
									</div>
								</vuestic-modal>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import { required, minLength, between } from 'vuelidate/lib/validators'
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";

	// import VuesticTreeRoot from './VuesticTreeRoot.vue'
	// import VuesticTreeNode from './VuesticTreeNode'
	// import VuesticTreeCategory from './VuesticTreeCategory'

	const axios = require("axios");

	export default {
		name: "schools",
		directives: { mask },
		// components: { VuesticTreeCategory, VuesticTreeNode, VuesticTreeRoot },
		computed: {
			...mapGetters({
				userCredential: "userCredential",
				// ...
			}),
		},
		mounted() {
			const self = this;

			this.$root.$on("closeModal", () => {
				self.closeModal();
			});

			// CRUD SCHOOLS
			// this.$root.$on('addSchool', (school) => {
			//     school.grades = []
			//     self.list.push(school)
			// })

			// this.$root.$on('editSchool', (school) => {
			//     var result = self.list.filter(item => {
			//       return item.id === school.id
			//     })

			//     var index = self.list.indexOf(result[0]);

			//     if (index > -1) {
			//       self.list.splice(index, 1, school)
			//     }

			// })

			this.$root.$on("deleteSchool", (school) => {
				var result = self.list.filter((item) => {
					return item.id === school.id;
				});

				var index = self.list.indexOf(result[0]);

				if (index > -1) {
					self.list.splice(index, 1);
				}
			});

			// CRUD SCHOOLS
			this.$root.$on("addGrade", (school, grade) => {
				//self.list.push(school)

				var result = self.list.filter((item) => {
					return item.id === school.id;
				});

				var indexSchool = self.list.indexOf(result[0]);

				self.list[indexSchool].grades.push(grade);
			});

			this.$root.$on("editGrade", (school, grade) => {
				var result = self.list.filter((item) => {
					return item.id === school.id;
				});

				var resultGrade = result[0].grades.filter((item) => {
					return item.id === grade.id;
				});

				var grades = result[0].grades;
				var indexSchool = self.list.indexOf(result[0]);
				var index = grades.indexOf(resultGrade[0]);

				self.list[indexSchool].grades.splice(index, 1, grade);
			});

			this.$root.$on("deleteGrade", (school, grade) => {
				var result = self.list.filter((item) => {
					return item.id === school.id;
				});

				var resultGrade = result[0].grades.filter((item) => {
					return item.id === grade.id;
				});

				var grades = result[0].grades;
				var index = grades.indexOf(resultGrade[0]);

				if (index > -1) {
					result[0].grades.splice(index, 1);
				}
			});

			//console.log(this.userCredential);

			let config = {
				headers: {
					Authorization: "Bearer " + this.userCredential.token,
				},
			};

			axios
				.get(
					process.env.VUE_APP_ROOT_API +
						"/schools?page=1&limit=100&sort=name asc&active=true",
					config
				)
				.then((response) => {
					self.list = response.data.data.schools;
				})
				.catch((e) => {
					this.errors.push(e);
				});

			this.emptySchool.project_id = this.userCredential.project_id;
			this.emptyGrade.project_id = this.userCredential.project_id;
		},

		//props: ['address', 'subscription'],

		data() {
			return {
				list: null,

				emptySchool: {
					name: "",
					address: {
						district: "",
						address_number: "",
						state: "",
						city: "",
						address1: "",
						address2: "",
						zip_postal_code: "",
					},
					contact_name: "",
					contact_email: "",
					contact_phone: "",
					type: 0,
					project_id: 1, //
				},

				emptyGrade: {
					role_id: "20",
					project_id: "1",
					name: "",
					grade_type_id: "",
					period_id: "",
					year: "",
					school_id: "",
					size: "",
					deadline: "",
					teacher: {
						//username: "",
						//password: "",
						//birthdate: "",
						email: "",
						name: "",
						phone: "",
						gender: "",
						//send_email: "",
						//auto_password: "",
					},
				},

				schoolModalType: null,
				schoolSelected: null,

				gradeModalType: null,
				gradeSelected: null,

				files: "",
				show: false,
				// subscriptionsData: null,
				// addressEdit: {
				//   addressLabel: ''
				// },
				// subscriberEditing: false,
				// subscriptionSend: {
				//   'subscriptions': [],
				//   'address': this.addressEdit
				// },
				// percentCall: 0,
				// isCreation: true,

				// openAddressField: false,

				// cepSearch: false,
				// errors: []
			};
		},
		methods: {
			jsonCopy(src) {
				return JSON.parse(JSON.stringify(src));
			},

			showSchool(school, type) {
				this.schoolModalType = type;

				// this.schoolModalType = null
				// this.schoolSelected = null

				let copy = this.jsonCopy(school);
				this.schoolSelected = copy;
				this.$refs.largeModal.open();

				//  this.$nextTick(() => {

				//  });
			},

			showGrade(grade, school, type) {
				this.gradeModalType = type;
				this.gradeSelected = this.jsonCopy(grade);

				this.schoolSelected = school;
				this.$refs.gradeModal.open();
			},

			closeModal() {
				if (this.$refs.largeModal) {
					this.$refs.largeModal.close();
				}

				if (this.$refs.gradeModal) {
					this.$refs.gradeModal.close();
				}
			},

			clickOnFile() {
				this.$refs.files.click();
			},

			submitFiles() {
				let formData = new FormData();

				for (var i = 0; i < this.files.length; i++) {
					let file = this.files[i];
					formData.append("csv", file);
				}

				axios
					.post(process.env.VUE_APP_ROOT_API + "/schools/import", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
							Authorization: "Bearer " + this.userCredential.token,
						},
					})
					.then(function () {
						alert("Upload feito com sucesso");
					})
					.catch(function () {
						alert("Erro, upload falhou");
					});
			},

			handleFileUpload() {
				this.files = this.$refs.files.files;
				this.submitFiles();
			},

			saveAddress() {
				const self = this;

				let config = {
					onDownloadProgress: (progressEvent) => {
						let percentCompleted = Math.floor(
							(progressEvent.loaded * 100) / progressEvent.total
						);
						this.percentCall = percentCompleted;
						// do whatever you like with the percentage complete
						// maybe dispatch an action that will update a progress bar or something
					},
				};

				this.$validator.validateAll().then((result) => {
					if (result) {
						// eslint-disable-next-line

						// this.addressEdit.nameRecipient = this.addressEdit.name + " " + this.addressEdit.lastname
						this.addressEdit.nameRecipient =
							this.addressEdit.name + " " + this.addressEdit.lastname;
						this.subscriptionSend.address = this.addressEdit;

						if (this.isCreation) {
							axios
								.post(
									process.env.VUE_APP_ROOT_API + "/customer/address",
									this.subscriptionSend,
									config
								)
								.then(function (response) {
									if (response.data.success !== false) {
										self.$router.push({ name: "adresses" });
										self.percentCall = 0;
									} else {
										self.errors.push(response.data.errors[0]);
									}
								})
								.catch(function (error) {
									console.log(error);
								});
						} else {
							axios
								.put(
									process.env.VUE_APP_ROOT_API + "/customer/address",
									this.subscriptionSend,
									config
								)
								.then(function (response) {
									if (response.data.success !== false) {
										self.$router.push({ name: "adresses" });
										self.percentCall = 0;
									} else {
										self.errors.push(response.data.errors[0]);
									}
								})
								.catch(function (error) {
									console.log(error);
								});
						}
					}
				});
			},

			showSubscriberEditing() {
				this.subscriberEditing = false;
			},
			hideSubscriberEditing() {
				this.subscriberEditing = false;
			},

			scrollParent(slug) {
				this.$router.push({ name: slug });
			},
		},
	};
</script>
<style lang="scss" scoped>
	.hide {
		display: none;
	}
	@media (max-width: 580px) {
		.btn-primary {
			width: 100%;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 5px;
			}
		}
	}

	.fiscal-notes ::v-deep .form-group:last-child {
		margin-bottom: 10px !important;
	}

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}
	small {
		font-family: Roboto;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		text-indent: 15px;
		margin-top: -5px;
		display: block;
		margin-bottom: 10px;
		// padding-left: 15px;
	}
	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}
	.address-info {
		margin-bottom: 15px;
	}
	.editing {
		margin-top: 15px;
	}
	.radio-button-wrapper {
		padding: 14px 11px;
		margin-top: 10px;

		@media (max-width: 990px) {
			padding: 20px 19px;
			margin-top: 10px;
		}
	}
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.add-new-card {
		border-radius: 10px;
		border: dashed 2px #2cd3d8;
		background-color: #ffffff;
		font-family: Nunito;
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 3.5;
		letter-spacing: 0.7px;
		text-align: center;
		color: #4965bc;
		margin-bottom: 20px;
		padding: 15px 0px;
		cursor: pointer;

		.plus {
			font-family: "Nunito";
			font-size: 35px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.7px;
			text-align: center;
			color: #ffffff;
			width: 40px;
			height: 40px;
			transform: rotate(-180deg);
			border-radius: 40px;
			border: solid 1px #4965bc;
			background-color: #4965bc;
			display: block;
			margin: 0 auto;
			margin-top: 20px;
			line-height: 36px;
		}
	}

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: white;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.my-data {
		/* Facebook bar */
		.facebook-tag {
			height: 65px;
			border-radius: 5px;
			background-color: #3a5998;
			color: white;
			padding: 14px 20px;
			margin-bottom: 20px;

			svg {
				font-size: 26px;
				margin-right: 0px;
				float: left;

				path {
					fill: #fff;
				}
			}

			.name {
				display: inline-block;
				margin-top: 1px;
				line-height: 37px;
			}

			.facebook-toggle {
				float: right;
				display: block;
				margin-top: 5px;
			}
		}
	}

	.change-password {
		border-radius: 10px;
		border: solid 2px #f0ebf5;
		background-color: #ffffff;
		padding: 24px 20px;
		position: relative;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 7px;
			font-family: "Roboto";

			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
		}

		h5 {
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.floated-icon {
			float: left;
			width: 36px;
			height: 36px;
			font-size: 30px;

			svg {
				position: relative;
				top: -5px;
				left: -3px;

				path {
					fill: #afa2ba;
				}
			}
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.my-data-table {
		h4 {
			margin-top: 50px;
			margin-bottom: 20px;
		}
	}
	.fiscal-notes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		.body-spacing {
			padding: 30px 30px 10px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}

			.btn-change-edit {
				border: none;
				color: #4965bc;
				background: transparent;
			}

			//  div:last-child{
			//   margin-bottom: 0px;
			// }
		}

		hr {
			border-top: solid 1px #f0ebf5;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		.company-code {
			text-transform: capitalize;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		// .panel:last-child{
		//   margin-bottom: 0px;
		// }
	}

	.address-boxes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		svg {
			path {
				fill: #afa2ba;
			}
		}

		.clube {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.body-spacing {
			padding: 30px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}
		}

		hr {
			border-top: solid 2px #f0ebf5;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}

		.address-row {
			padding: 16px 0px;
			position: relative;
			border-bottom: solid 1px #f0ebf5;
			b {
				font-size: 16px;
				color: #000000;
			}
			:last-child {
				border-bottom: none;
			}
		}
	}

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
</style>
